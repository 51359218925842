var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "3" } },
            [
              _c("div", { staticClass: "d-flex justify-lg-space-between" }),
              _c(
                "v-card",
                { staticClass: "mx-auto" },
                [
                  _c("portfolio-details", {
                    attrs: {
                      portfolio: _vm.portfolio,
                      samples: _vm.samples[0],
                      last: _vm.last[0],
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "9" } },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "mb-3",
                  model: {
                    value: _vm.currentTab,
                    callback: function ($$v) {
                      _vm.currentTab = $$v
                    },
                    expression: "currentTab",
                  },
                },
                [
                  _c(
                    "v-tab",
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2",
                          attrs: { dense: "", size: "16" },
                        },
                        [_vm._v("fa fa-chart-line")]
                      ),
                      _vm._v(" Graphique "),
                    ],
                    1
                  ),
                  _c(
                    "v-tab",
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2",
                          attrs: { dense: "", size: "16" },
                        },
                        [_vm._v("fa fa-chart-pie")]
                      ),
                      _vm._v(" Composition "),
                    ],
                    1
                  ),
                  _c(
                    "v-tab",
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-2",
                          attrs: { dense: "", size: "16" },
                        },
                        [_vm._v("fa fa-clock")]
                      ),
                      _vm._v(" Historique "),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.currentTab,
                    callback: function ($$v) {
                      _vm.currentTab = $$v
                    },
                    expression: "currentTab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    [
                      !_vm.loading
                        ? _c("portfolio-chart", {
                            attrs: { portfolio: _vm.portfolio },
                            on: { setData: _vm.setData },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { md: "12" } },
                            [
                              _c("v-simple-table", { attrs: { dense: "" } }, [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [_vm._v("NAME")]),
                                    _c("th", [_vm._v("ACTION")]),
                                    _c("th", [_vm._v("VALO")]),
                                    _c("th", [_vm._v("SOLIDITE")]),
                                    _c("th", [_vm._v("PERFORMANCE")]),
                                    _c("th", [_vm._v("SCORE")]),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: { "text-align": "right" },
                                      },
                                      [_vm._v("%")]
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.compositionChunks,
                                    function (component) {
                                      return _c(
                                        "tr",
                                        { key: "component-" + component.id },
                                        [
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-size": ".8em",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "mr-1",
                                                  staticStyle: {
                                                    "font-size": "16px",
                                                  },
                                                },
                                                [_vm._v(_vm._s() + " ")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    component.company.name.toUpperCase()
                                                  ) +
                                                  " (" +
                                                  _vm._s(
                                                    component.company.symbol
                                                  ) +
                                                  ") "
                                              ),
                                              _c("small"),
                                            ]
                                          ),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                parseFloat(
                                                  component.nbr_action
                                                ).toFixed(3)
                                              ) + " "
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  parseFloat(
                                                    component.valo
                                                  ).toFixed(3)
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  parseFloat(
                                                    component.solidite
                                                  ).toFixed(3)
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  parseFloat(
                                                    component.performance
                                                  ).toFixed(3)
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  parseFloat(
                                                    component.score
                                                  ).toFixed(3)
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "right",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    parseFloat(
                                                      component.percentage
                                                    ).toFixed(2)
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    [
                      [
                        _c("v-card", [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: 2 } },
                                [
                                  _c(
                                    "v-tabs",
                                    {
                                      attrs: { color: "primary", vertical: "" },
                                      model: {
                                        value: _vm.tabl,
                                        callback: function ($$v) {
                                          _vm.tabl = $$v
                                        },
                                        expression: "tabl",
                                      },
                                    },
                                    _vm._l(
                                      _vm.portfolio.portfolioVersions,
                                      function (itemx, idx) {
                                        return idx <
                                          _vm.portfolio.portfolioVersions
                                            .length -
                                            1
                                          ? _c("v-tab", { key: idx }, [
                                              _vm._v(
                                                " " + _vm._s(itemx.name) + " "
                                              ),
                                            ])
                                          : _vm._e()
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: 10 } },
                                [
                                  _c(
                                    "v-tabs-items",
                                    {
                                      model: {
                                        value: _vm.tabl,
                                        callback: function ($$v) {
                                          _vm.tabl = $$v
                                        },
                                        expression: "tabl",
                                      },
                                    },
                                    _vm._l(
                                      _vm.portfolio.portfolioVersions,
                                      function (itemx, idx) {
                                        return idx <
                                          _vm.portfolio.portfolioVersions
                                            .length -
                                            1
                                          ? _c(
                                              "v-tab-item",
                                              { key: idx },
                                              [
                                                _c("h5", [
                                                  _vm._v(
                                                    "date de création du " +
                                                      _vm._s(itemx.name) +
                                                      " : " +
                                                      _vm._s(itemx.date_version)
                                                  ),
                                                ]),
                                                _c(
                                                  "v-simple-table",
                                                  { attrs: { dense: "" } },
                                                  [
                                                    _c("thead", [
                                                      _c("tr", [
                                                        _c("th", [
                                                          _vm._v("NAME"),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v("ACTION"),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v("CLOSE"),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v("VALO"),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v("SOLIDITE"),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v("PERFORMANCE"),
                                                        ]),
                                                        _c("th", [
                                                          _vm._v("SCORE"),
                                                        ]),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticStyle: {
                                                              "text-align":
                                                                "right",
                                                            },
                                                          },
                                                          [_vm._v("%")]
                                                        ),
                                                      ]),
                                                    ]),
                                                    _c(
                                                      "tbody",
                                                      _vm._l(
                                                        itemx.portfolioComposition,
                                                        function (
                                                          component,
                                                          ids
                                                        ) {
                                                          return _c(
                                                            "tr",
                                                            { key: ids },
                                                            [
                                                              [
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          ".8em",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "mr-1",
                                                                        staticStyle:
                                                                          {
                                                                            "font-size":
                                                                              "16px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s() +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          component.company.name.toUpperCase()
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    _c(
                                                                      "small",
                                                                      [
                                                                        _vm._v(
                                                                          " (" +
                                                                            _vm._s(
                                                                              component
                                                                                .company
                                                                                .symbol
                                                                            ) +
                                                                            ")"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        component.nbr_action
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        parseFloat(
                                                                          component.close
                                                                        ).toFixed(
                                                                          3
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        parseFloat(
                                                                          component.valo
                                                                        ).toFixed(
                                                                          3
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        parseFloat(
                                                                          component.solidite
                                                                        ).toFixed(
                                                                          3
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        parseFloat(
                                                                          component.performance
                                                                        ).toFixed(
                                                                          3
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        parseFloat(
                                                                          component.score
                                                                        ).toFixed(
                                                                          3
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "text-align":
                                                                          "right",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          parseFloat(
                                                                            component.percentage
                                                                          ).toFixed(
                                                                            2
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                            ],
                                                            2
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }