









































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import { MsiIndex, Portfolio } from "@/smartmsi";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

import StockExchangeHistory from "@/components/stock/StockExchangeHistory.vue";
import Flashable from "@/components/stock/Flashable.vue";
import StockSamples from "@/components/stock/StockSamples.vue";
import PortfolioChart from "@/components/portefeuille/PortfolioChart.vue";
import { AxiosResponse } from "axios";
import PortfolioDetails from "@/components/portefeuille/PortfolioDetails.vue";


@Component({
  components: {PortfolioDetails, PortfolioChart, StockSamples, Flashable, StockExchangeHistory},
  mixins: [formatter]
})
export default class PortfolioView extends Vue {

  @Prop() portfolioId!: number;

  startYear = '';
  endYear = '';

  currentTab = 0;
  tabl= 0;

  toggleBase = 0;

  nbrVersion = 0;
  msiYear = null;

  index: Partial<MsiIndex> = {};
  portfolio: Partial<Portfolio> = {};
  loading = true;

  withMsi = 0;
  msiBase = 0;

  live = false;

  last = [this.toggleBase == 0 ? 0 : 20000, 0];


  samples = [{
    '3M': 0,
    '6M': 0,
    'JAN': 0,
    'YTD': 0,
    'ALL': 0
  }, {
    '3M': 0,
    '6M': 0,
    'JAN': 0,
    'YTD': 0,
    'ALL': 0
  }
  ];


  flag(code: string) {
    return getUnicodeFlagIcon(code);
  }


  get compositionChunks() {
    if (this.portfolio && this.portfolio.portfolioVersions) {
      this.nbrVersion = this.portfolio.portfolioVersions.length;
      const c = this.portfolio.portfolioVersions[this.nbrVersion - 1]?.portfolioComposition;
      return c;
      // if (c) {
      //   const l = c.length;
      //   return [
      //     c.slice(0, l / 2),
      //     c.slice(l / 2),
      //   ];
      // }
    }

    return [];
  }

  setData(d: Record<string, Record<string, number>[]>) {
    this.samples = d.samples;
    this.last = d.last;
  }

  loadMsiIndex() {
    return this.$api.get(`portfolio/${this.portfolioId}?expand=portfolioVersions.portfolioComposition.company`).then((res: AxiosResponse<MsiIndex>) => {
      this.portfolio = res.data;
    
        const now = this.portfolio.last.now;
        this.last = [
          now, //this.toggleBase == 0 ? now : 20000,
          now
        ];
  
    });
  }

  truncate(text: string, length: number) {
    if (text.length <= length + 3) {
      return text;
    }

    return text.substr(0, length) + ' ..';
  }

  created() {
    this.loadMsiIndex().then(() => {
      this.loading = false;
    });
  }

  reversedPortfolioComposition(portfolioComposition : any) {
  // Convert the object into an array of its values and then reverse the array
  return Object.values(portfolioComposition).reverse();
}

}
